<template>
  <div class="">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              variant="primary"
              type="button"
              class="btn btn-primary btn-block mb-3"
              @click="redrToAssets()"
              v-if="!is_staff"
            >
              My Assets
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
            style="font-size: 12px"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                
                class="text-snow"
                v-for="filter in filteredTaskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">{{
                  filter.title
                }}</span>
              </b-list-group-item>
            </b-list-group>
            <!-- Tags -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1" v-if="!$store.state.app.isAdmin">
                Team Tasks
              </h6>
            </div>
            <b-list-group
              class="list-group-labels"
              v-if="!$store.state.app.isAdmin"
            >
              <b-list-group-item
                v-for="item in taskTags"
                :key="item.team_id"
                :active="
                  isDynamicRouteActive({
                    name: 'team-tasks',
                    params: { id: item.team_id },
                  })
                "
                :to="{ name: 'team-tasks', params: { id: item.team_id } }"
                @click="$emit('close-left-sidebar')"
              >
                <span class="bullet bullet-sm mr-1" :class="`bullet-success`" />
                <span>{{ item.team_name }}</span>
              </b-list-group-item>
            </b-list-group>

            <b-list-group
              class="list-group-filters pt-5"
              v-if="deletedTasks.length > 0"
            >
              <b-list-group-item
                class="bg-light-danger border-danger text-danger"
                style="color: #ea5455 !important"
                v-for="filter in filteredDeletedTask"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">{{
                  filter.title
                }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BListGroup,
  BListGroupItem,
  BFormSelect,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { isDynamicRouteActive } from "@core/utils/utils";
export default {
  setup() {
    return {
      isDynamicRouteActive,
    };
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    BFormSelect,
  },

  data() {
    return {
      tasks: [],
      taskTags: [],
      task: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      taskFilters: [
        { title: "My Tasks", icon: "MailIcon", route: { name: "task" } },
        {
          title: "Tasks Assigned",
          icon: "StarIcon",
          route: { name: "task-search", params: { type: "all" } },
        },
        {
          title: "Retest Task",
          icon: "TargetIcon",
          route: { name: "task-search", params: { type: "retest" } },
        },
        {
          title: "All Tasks",
          icon: "TargetIcon",
          route: { name: "task-search", params: { type: "all-org-task" } },
        },
      ],
      deleteTaskFilters: [
        {
          title: "Deleted Tasks",
          icon: "TrashIcon",
          route: { name: "task-search", params: { type: "deleted-task" } },
        },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organizations: [{ value: null, text: "--Select Organization--" }],
    };
  },
  props: {
    deletedTasks: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredTaskFilters() {
      const taskFilters = [
        { title: "My Tasks", icon: "MailIcon", route: { name: "task" } },
        {
          title: "Tasks Assigned",
          icon: "StarIcon",
          route: { name: "task-search", params: { type: "all" } },
        },
        {
          title: "All Tasks",
          icon: "TargetIcon",
          route: { name: "task-search", params: { type: "all-org-task" } },
        },
      ];

      if (this.$store.state.app.user.is_staff) {
        taskFilters.splice(1, 0, {
          title: "Retest Request",
          icon: "TargetIcon",
          route: { name: "task-search", params: { type: "retest" } },
        });
      }

      return taskFilters;
    },
    filteredDeletedTask() {
      const deleteTaskFilters = [
        {
          title: "Deleted Tasks",
          icon: "TrashIcon",
          route: { name: "task-search", params: { type: "deleted-task" } },
        },
      ];
      return deleteTaskFilters;
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      if (!this.$store.state.app.isAdmin) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "user/teams",
        };
        this.$http(options).then((res) => {
          this.taskTags = res.data;
        });
      }
    },
    redrToAssets() {
      this.$router.push({ name: "Assets" });
    },
  },
};
</script>

<style>
.dark-layout a:hover {
    color: #fff !important;
}


</style>