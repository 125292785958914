<template>
  <b-card>
    <div class="row" style="height: inherit">
      <div class="col-2 border-right" align-self="start">
        <LeftSideBar :deletedTasks="deletedTasks" />
      </div>
      <div class="col-10 todo-app-list">
        <div
          class="app-fixed-search d-flex align-items-center border-bottom pb-1"
        >
          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

          <div
            class="d-flex align-content-center justify-content-between col-5"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search Task"
                @change="searchFn"
                v-model="name_filter"
              />
            </b-input-group>
            <b-form-select
              v-if="this.$route.params.type != 'deleted-task'"
              class="ml-1"
              @change="searchFn"
              v-model="asset_group"
              :options="assetGroups"
              style="border-radius: 5px"
            >
            </b-form-select>
          </div>

          <div class="d-flex align-content-right float-right col-7">
            <b-input-group>
              <!-- <v-select
                id="asset_filter"
                class=""
                style="width: 43%"
                v-model="asset_filter"
                label="text"
                :options="asset_filter_options"
                @search="fetchOptions"
                placeholder="Asset Name or Nickname"
                autocomplete
                @input="searchFn"
              /> -->
              <b-input-group class="input-group-merge" style="width: 30%">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-prepend>
                <b-form-input
                  style="width: 30%"
                  id="asset_filter"
                  v-model="assetname_filter"
                  @change="searchFn"
                  name="name"
                  placeholder="Search with Asset"
                />
              </b-input-group>
              <b-form-select
                class="ml-1"
                @change="searchFn"
                v-model="status_filter"
                :options="status_filter_options"
                style="border-radius: 5px"
              >
              </b-form-select>
              <b-form-select
                class="ml-1"
                @change="searchFn"
                v-model="priority_filter"
                :options="priority_filter_options"
                style="border-radius: 5px"
              >
              </b-form-select>
            </b-input-group>
          </div>
        </div>

        <!-- Todo search ends -->

        <div style="height: inherit">
          <div
            class="app-fixed-search d-flex align-items-center my-1"
            v-if="selectedTasks.length > 0"
          >
            <span
              title="Click to select all"
              style="cursor: pointer; margin-right: 2px"
              class="text-primary mr-1"
              @click="selectall()"
              >Select All</span
            >
            <span
              title="Click to clear"
              style="cursor: pointer"
              class="text-primary"
              @click="clearall()"
            >
              Clear All</span
            >
            <div class="clearfix ml-auto">
              <b-form-select
                v-if="this.$route.params.type != 'deleted-task'"
                v-model="selected_action"
                :options="filteredOptions"
                @change="doBulkAction"
                size="sm"
              >
              </b-form-select>
              <b-form-select
                v-else
                v-model="selected_action"
                :options="revert_selected_options"
                @change="revert"
                size="sm"
              >
              </b-form-select>
            </div>
          </div>
          <div style="overflow-x: auto; overflow-y: auto">
            <div class="d-flex justify-content-center my-1" v-if="isLoading">
              <b-spinner class="float-right" label="Floated Right" />
            </div>
            <table
              class="table table-striped my-2 w-100"
              hover
              responsive
              v-if="!isLoading"
              id="my-table"
              :per-page="perPage"
            >
              <thead>
                <tr class="text-white text-bold bg-primary">
                  <td>Select</td>
                  <td>Name</td>
                  <td>Asset</td>
                  <td>Status</td>
                  <td>Priority</td>
                  <td>Created At</td>
                  <td>Due Date</td>
                  <td>
                    <span>{{ colLabel }}</span>
                  </td>
                  <!-- <td v-if="paramAll">
                    <span>Assigned By</span>
                  </td>
                  <td v-else>
                    <span>Assigned To</span>
                  </td> -->
                </tr>
              </thead>
              <tbody v-if="this.tasks.length">
                <tr
                  v-for="task in tasks"
                  :key="task.task_id"
                  :class="{ completed: task.task_status == 2 }"
                  @click="showSidebar(task)"
                >
                  <td>
                    <b-form-checkbox
                      :checked="task.task_status == 2"
                      @click.native.stop
                      v-model="selectedTasks"
                      :value="task.task_id"
                    />
                  </td>
                  <td>
                    <div
                      class="d-flex justify-content-between"
                      style="white-space: nowrap; overflow: hidden"
                    >
                      <span
                        class="todo-title"
                        v-html="breakTextIntoLines(task.task_title)"
                      ></span>
                    </div>
                  </td>
                  <td>
                    <span v-if="task.asset === null">{{
                      task.asset_name
                    }}</span>
                    <span v-else-if="task.asset_profile.is_deleted === true">{{
                      task.asset_name
                    }}</span>
                    <router-link
                      v-else
                      :to="'/assets/scan/' + task.asset_profile.asset_id"
                      >{{ task.asset_profile.asset_nickname }}
                    </router-link>
                  </td>
                  <td>
                    <div class="badge-wrapper mr-1">
                      <!-- Task Status -->
                      <b-badge
                        style="margin-right: 1px"
                        variant="primary"
                        v-if="task.task_status == 0"
                        >Assigned</b-badge
                      >
                      <b-badge
                        style="margin-right: 1px"
                        variant="info"
                        v-else-if="task.task_status == 4"
                        >Re-opened</b-badge
                      >
                      <b-badge
                        style="margin-right: 1px"
                        variant="warning"
                        v-else-if="task.task_status == 1"
                        >In Progress</b-badge
                      >
                      <b-badge
                        style="margin-right: 1px"
                        variant="success"
                        v-else-if="task.task_status == 2"
                        >Completed</b-badge
                      >
                      <b-badge
                        style="margin-right: 3px"
                        variant="secondary"
                        v-else
                        >UnKnown</b-badge
                      >
                    </div>
                  </td>
                  <td>
                    <div class="badge-wrapper">
                      <!-- Task Priority -->
                      <b-badge
                        style="margin-right: 1px"
                        variant="primary"
                        v-if="task.task_priority == 1"
                        >Low</b-badge
                      >
                      <b-badge
                        style="margin-right: 1px"
                        variant="info"
                        v-else-if="task.task_priority == 2"
                        >Medium</b-badge
                      >
                      <b-badge
                        style="margin-right: 1px"
                        variant="warning"
                        v-else-if="task.task_priority == 3"
                        >High</b-badge
                      >
                      <b-badge
                        style="margin-right: 1px"
                        variant="danger"
                        v-else-if="task.task_priority == 4"
                        >Critical</b-badge
                      >
                      <b-badge variant="secondary" v-else>UnKnown</b-badge>
                    </div>
                  </td>
                  <td>
                    <div
                      class="d-flex justify-content-between"
                      style="white-space: nowrap; overflow: hidden"
                    >
                      <span style="text-overflow: ellipsis">{{
                        task.created_at | formatdate
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      class="d-flex justify-content-between"
                      style="white-space: nowrap; overflow: hidden"
                    >
                      <span style="text-overflow: ellipsis">{{
                        task.due_date | notimeformat
                      }}</span>
                    </div>
                  </td>

                  <!-- <td>
                    <span>
                      <small
                        class="text-nowrap text-primary mr-1"
                        v-if="task.task_assigned_to"
                        ><b>{{ task.task_assigned_to.full_name }}</b>
                      </small>
                    </span>
                  </td> -->
                  <td>
                    <span
                      v-if="
                        $route.params.type == all ||
                        $route.params.type == 'deleted-task' ||
                        $route.params.type == 'retest'
                      "
                    >
                      <small class="text-nowrap text-primary mr-1"
                        ><b>{{ task.task_assigned_by.full_name }}</b>
                      </small>
                    </span>
                    <span v-else>
                      <small
                        class="text-nowrap text-primary mr-1"
                        v-if="task.task_assigned_to"
                        ><b>{{ task.task_assigned_to.full_name }}</b>
                      </small>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="8" class="text-center">
                    <div
                      class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
                    >
                      <feather-icon
                        icon="DatabaseIcon"
                        size="25"
                        class="mb-50"
                      />
                      <h3 class="font-weight-bolder">No Tasks Found</h3>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="m-2">
            <div class="row">
              <div class="d-flex align-items-center justify-content-end col-12">
                <b-pagination-nav
                  v-model="currentPage"
                  :numberOfPages="total"
                  :total-rows="rows"
                  :link-gen="linkGen"
                  :per-page="perPage"
                  use-router
                  v-if="!loading"
                  aria-controls="my-table"
                ></b-pagination-nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- task detail page -->
      <b-sidebar
        id="sidebar-task-handler"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
      >
        <TaskEditForm
          :task="task"
          :callBack="afterTaskUpdate"
          :closeSidebar="closeSidebar"
        />
      </b-sidebar>
      <!-- task Assign section -->
      <b-sidebar
        id="sidebar-task-handler-taskform"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
      >
        <TaskBulkAssignForm
          :tasks="selectedTasks"
          :callBack="afterTaskBulkUpdate"
          :closeSidebar="closeTaskBulkAssignor"
          :organization="organization"
        />
      </b-sidebar>
    </div>
  </b-card>
</template>
<script>
import vSelect from "vue-select";
import {
  BCard,
  BForm,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BSidebar,
  BPagination,
  BPaginationNav,
  BModal,
  BSpinner,
} from "bootstrap-vue";

// import Tasks from "./components/tasks.vue";
import LeftSideBar from "./left_sidebar.vue";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import TaskEditForm from "./TaskForm.vue";
import TaskBulkAssignForm from "./TaskBulkUpdate.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    BPagination,
    BPaginationNav,
    BSidebar,

    BFormSelect,
    vSelect,
    // Tasks,
    LeftSideBar,
    TaskBulkAssignForm,
    TaskEditForm,
    BModal,
    BSpinner,
  },

  data() {
    return {
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      loading: true,
      tasks: [],
      task: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      name_filter: "",
      assetname_filter: "",
      asset_filter: null,
      asset_filter_options: [],
      status_filter: 0,
      status_filter_options: [
        { value: null, text: "--Status--" },
        { value: 0, text: "Open & Re-opened" },
        { value: 1, text: "In Progress" },
        { value: 2, text: "Closed" },
      ],
      priority_filter: null,
      priority_filter_options: [
        { value: null, text: "--Priority--" },
        { value: 1, text: "Low" },
        { value: 2, text: "Medium" },
        { value: 3, text: "High" },
        { value: 4, text: "Critical" },
      ],
      asset_group: null,
      assetGroups: [{ value: null, text: "--Select Group--" }],

      // group_filter: null,
      // group_filter_options: [{ value: null, text: "--group--" }],
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      selected_action: null,
      selectedTasks: [],
      with_selected_options: [
        { value: null, text: "--With Selected--" },
        { value: "reassign", text: "Assign to" },
        { value: "mark_as_closed", text: "Mark as Closed" },
        { value: "mark_as_open", text: "Mark as Open" },
        { value: "mark_as_in_progress", text: "Mark as In Progress" },
        { value: "delete", text: "Delete" },
      ],
      revert_selected_options: [
        { value: null, text: "--With Selected--" },
        { value: "revert", text: "Revert" },
      ],
      vuln_id: "",
      currentStatus: this.$route.query.status,
      paramAll: "",
      colLabel: "",
      deletedTasks: [],
      filter: [],
      isLoading: false,
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
    $route(to, from) {
      if (!this.$route.query.page) {
        this.loadData();
        this.assignedColumLabel();
        this.refreshSearch();
      }
      this.onVerticalCollapse();
      // if (this.$route.query.taskId) {
      //   debugger;
      //   this.openTaskSidebar(this.$route.query.taskId);
      // }
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
    notimeformat: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY");
      }
    },
  },
  computed: {
    filteredOptions() {
      // Check if the "type" parameter exists in the current route
      const typeParamExists = this.$route.params && this.$route.params.type;
      // If the "type" parameter does not exist, filter out the "delete" option
      return typeParamExists
        ? this.with_selected_options
        : this.with_selected_options.filter(
            (option) => option.value !== "delete"
          );
    },
  },
  mounted: function () {
    this.onVerticalCollapse();
    this.loadData();
    this.loadGroup();
    this.fetchOptions();
    // if (this.$route.query.taskId) {
    //   debugger;
    //   this.openTaskSidebar(this.$route.query.taskId);
    // }
  },
  created: function () {
    this.assignedColumLabel();
    this.checkDeletedTask();
    // if (this.$route.query.taskId) {
    //   this.openTaskSidebar(this.$route.query.taskId);
    // }
  },

  methods: {
    assignedColumLabel() {
      this.colLabel =
        !this.$route.params.type ||
        this.$route.params.type == "deleted-task" ||
        this.$route.params.type == "retest"
          ? "Assigned By"
          : "Assigned To";
    },
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    breakTextIntoLines(text) {
      const maxCharacters = 30;
      const words = text.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },

    refreshSearch: function () {
      this.name_filter = "";
      this.asset_group = null;
      this.assetname_filter = "";
      this.status_filter = null;
      this.priority_filter = null;
    },

    loadGroup: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/list-all-asset-groups?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let g = {
            value: res.data[key].group_id,
            text: res.data[key].group_name,
          };
          self.assetGroups.push(g);
        });
      });
    },
    checkDeletedTask: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/deleted-tasks",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.deletedTasks = res.data.results;
      });
    },

    loadData: function () {
      this.isLoading = true;
      this.selectedTasks = [];
      this.selected_action = null;
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      this.loading = true;

      // if (reset) this.currentPage = 1;
      this.asset_filter_options = [];

      var url = "";

      if (this.$route.params.type == "all") {
        url = process.env.VUE_APP_BASEURL + "task/task/my-tasks-created/";
      } else if (this.$route.params.type === "retest") {
        url = process.env.VUE_APP_BASEURL + "task/task/task-from-org/";
      } else if (this.$route.params.type === "all-org-task") {
        url = process.env.VUE_APP_BASEURL + "task/task/list-all-org-tasks";
      } else if (this.$route.params.type === "deleted-task") {
        url = process.env.VUE_APP_BASEURL + "task/task/deleted-tasks";
      } else {
        url = process.env.VUE_APP_BASEURL + "task/task/my-tasks/";
      }

      if (this.organization != null) {
        url = url + "?org_id=" + this.organization;
      }
      if (this.$route.query.status && this.status_filter == 0) {
        url = url + "&status=" + this.currentStatus;
      }

      if (this.$route.params.id)
        var url =
          process.env.VUE_APP_BASEURL +
          "task/task/team-tasks?query=true" +
          "&team_id=" +
          this.$route.params.id;
      if (this.status_filter != null) {
        url = url + "&status=" + this.status_filter;
      }

      if (this.currentPage != null) {
        url = url + "&page=" + this.currentPage;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.tasks = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.isLoading = false;
        this.loading = false;
        if (this.$route.query.taskId) {
          this.openTaskSidebar(this.$route.query.taskId);
        }
      });
    },

    searchFn: function (reset = true) {
      this.isLoading = true;
      this.asset_filter_options = [];
      var url = "";
      if (reset) this.currentPage = 1;
      if (this.$route.params.type === "all") {
        url = process.env.VUE_APP_BASEURL + "task/task/my-tasks-created";
      } else if (this.$route.params.type === "retest") {
        url = process.env.VUE_APP_BASEURL + "task/task/task-from-org/";
      } else if (this.$route.params.type === "all-org-task") {
        url = process.env.VUE_APP_BASEURL + "task/task/list-all-org-tasks";
      } else if (this.$route.params.type === "deleted-task") {
        url = process.env.VUE_APP_BASEURL + "task/task/deleted-tasks";
      } else {
        url = process.env.VUE_APP_BASEURL + "task/task/my-tasks";
      }

      url = url + "?query=true";
      if (this.$route.params.id)
        var url =
          process.env.VUE_APP_BASEURL +
          "task/task/team-tasks?team_id=" +
          this.$route.params.id;

      if (this.$route.query.status && this.status_filter == 0) {
        url = url + "&status=" + this.currentStatus;
      }
      if (this.name_filter != "") url = url + "&name=" + this.name_filter;
      if (this.assetname_filter != "") {
        url = url + "&asset_name=" + this.assetname_filter;
        this.filter.push({ asset_name: this.assetname_filter });
      }
      if (this.asset_filter != null)
        url = url + "&asset_id=" + this.asset_filter.value;
      if (this.status_filter != null)
        url = url + "&status=" + this.status_filter;
      if (this.priority_filter != null)
        url = url + "&priority=" + this.priority_filter;
      if (this.organization != null) url = url + "&org_id=" + this.organization;
      if (this.asset_group != null) {
        url = url + "&group_id=" + this.asset_group;
      }
      if (this.currentPage != null) {
        url = url + "&page=" + this.currentPage;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.tasks = res.data.results;
        this.isLoading = false;
      });
    },
    //----------- fetch data
    openTaskSidebar(taskId) {
      const task = this.tasks.find(t => t.task_id === parseInt(taskId))
      if (task) {
        this.task = task;
        this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      }
    },
    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;

        this.$http(a_options).then((res) => {
          self.asset_filter_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_filter_options.push(a);
          });
        });
      }
    },

    // for task table
    clearall: function () {
      this.selectedTasks = [];
    },
    selectall: function () {
      this.selectedTasks = [];
      this.tasks.forEach((item) => {
        const index = this.selectedTasks.indexOf(item.selectedTasks);
        if (index < 0) {
          this.selectedTasks.push(item.task_id);
        }
      });
    },

    showSidebar: function (taskData) {
      this.task = taskData;
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
    },
    afterTaskUpdate: function () {
      this.callBack();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    afterTaskBulkUpdate: function () {
      this.selectedTasks = [];
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler-taskform");
      this.callBack();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    closeTaskBulkAssignor: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler-taskform");
    },
    revert: function () {
      if (this.selected_action === "revert") {
        let data = {
          tasks: this.selectedTasks,
        };
        const options = {
          method: "POST",
          headers: { "content-type": "application/json" },
          data: data,
          url: process.env.VUE_APP_BASEURL + "task/task/revert-task/",
        };
        var self = this;
        this.$http(options).then((res) => {
          if (res.data.status == "success") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
          this.loadData();
        });
      }
    },
    changeStatus: function (status) {
      var self = this;
      let data = {
        action_code: 2,
        status: status,
        tasks: this.selectedTasks,
      };
      this.$http({
        method: "PATCH",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/bulk-update/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedTasks = [];
          this.loadData();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
    },
    doBulkAction: function () {
      if (!this.selectedTasks.length) {
        alert("Please select some items");
        this.selected_action = null;
      }
      switch (this.selected_action) {
        case "reassign":
          // Action to mitigate
          // Pop up for the task assignee
          this.$root.$emit(
            "bv::toggle::collapse",
            "sidebar-task-handler-taskform"
          );
          break;
        case "mark_as_closed":
          if (confirm("Do you really want to close the selected tasks?")) {
            this.changeStatus(2);
          }
          this.selected_action = null;
          break;
        case "mark_as_in_progress":
          if (
            confirm(
              "Do you really want to mark as false positive the selected tasks?"
            )
          ) {
            this.changeStatus(1);
          }
          this.selected_action = null;
          break;
        case "mark_as_open":
          if (confirm("Do you really want to (re)open the selected issues?")) {
            this.changeStatus(0);
          }
          this.selected_action = null;
          break;

        case "delete":
          if (confirm("Do you really want to delete the selected issues?")) {
            this.changeStatus(9);
          }
          this.selected_action = null;
          break;

        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #e3ebff;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .dark-layout .table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #242b3d;
}
</style>
